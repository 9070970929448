.tns-outer {
  padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
  &.ms-touch {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; // Hides the scrollbar.
    -ms-scroll-chaining: none; // Prevents Metro from swiping to the next tab or app.
    -ms-scroll-snap-type: mandatory; // Forces a snap scroll behavior on your images.
    -ms-scroll-snap-points-x: snapInterval(0%, 100%); // Defines the y and x intervals to snap to when scrolling.
  }
}
.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
  .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
  .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
  }
}
.tns-horizontal.tns-no-subpixel {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  .tns-item {
    float: left;
    margin-right: -100%;
  }
}
.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  // overflow: hidden;
  .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: opacity 0s;
    -moz-transition: opacity 0s;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
  }
}
.tns-nav {
  button { vertical-align: middle; }
}
.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
  &.loaded { opacity: 1; }
}
[hidden] { display: none !important; }
[data-controls], [data-action], [data-nav]  {
  cursor: pointer;
  border-width: 0;
  padding: 0;
}
.tns-ovh { overflow: hidden; }
.tns-hdx { overflow-x: hidden; }
.tns-hdy { overflow-y: hidden; }
.tns-visually-hidden { position: absolute; left: -10000em; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

// style for testing CSS3 mediaquery support
@media all and (min-width: 1px) {
  .tns-mq-test { position: absolute; }
}